import Vue from 'vue'
import App from './App.vue'


import APIService from './services/APIService'
import vuetify from './plugins/vuetify';
Vue.prototype.$http = APIService;

Vue.config.productionTip = false;


import store from '@/store'
import i18n from './i18n'
import router from './router'

import VueGeolocation from 'vue-browser-geolocation';
Vue.use(VueGeolocation);

import VueCurrencyInput from 'vue-currency-input'
Vue.use(VueCurrencyInput, {});

const moment = require('moment');
require('moment/locale/it');
Vue.use(require('vue-moment'), {moment});

import Sticky from 'vue-sticky-directive'
Vue.use(Sticky)

import VueStickyKit from 'vue-stickykit'
Vue.use(VueStickyKit)


var VueScrollTo = require('vue-scrollto');

Vue.use(VueScrollTo)

// You can also pass in the default options
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

new Vue({
  vuetify,
  store,
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')
