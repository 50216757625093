<template>

  <div>

    <v-menu v-model="menu1" :close-on-content-click="false" max-width="290" v-if="display_type == 'calendar'">

      <template v-slot:activator="{ on, attrs }">

        <v-text-field
            :value="computedDateFormattedMomentjs"
            :label="label"
            readonly
            v-bind="attrs"
            v-on="on">

        </v-text-field>

      </template>

      <v-date-picker :min="min_date" :max="max_date" :allowed-dates="allowedDates" v-model="date" @change="selectDate()" no-title></v-date-picker>

    </v-menu>

    <v-select class="ec_ord_ass_select" color="#027d88" v-else v-model="date" :label="label" :items="items" @change="selectDate()"></v-select>

  </div>

</template>


<script>



import moment from 'moment'


export default {
  name: 'datepicker',
  data: () => ({

    date: null,
    menu1: false,
    menu2: false,


  }),

  props: {
    display_type: {default: function(){return 'select';}},
    label: {default: function(){return null;}},
    date: {default: function(){return null;}},
    dates: {default: function(){return null;}}


  },


  methods: {


    selectDate(){

      this.$emit('select',this.date);
      this.menu1 = false;

    },

    allowedDates: function(date){

      if(this.dates == null){return true;}
      return this.dates.indexOf(date) !== -1;

    }

  },

  mounted: function(){


    if(this.date == null){

      this.date = this.min_date;

    }

  },

  computed: {
    computedDateFormattedMomentjs () {
      return this.date ? moment(this.date).format('dddd, MMMM Do YYYY') : ''
    },


    items: function(){


      let self = this;
      let dates = [];


      self.dates.forEach(element => {

        dates.push({text: moment(element).format('dddd, MMMM Do YYYY'), value: element});

      });

      return dates;


    },



    min_date: function(){

      let date = new Date();
      return date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();

    },

    max_date: function(){

      let date = new Date();
      date.setDate(date.getDate() + 3);
      return date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();

    }
  },
}
</script>