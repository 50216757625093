<template>

  <div class="StepSearch">

    <v-expansion-panels active-class="opened" class="ec_order_assistant_container"  v-model="panel">
      <v-expansion-panel class="ec_expansion_panel">
        <v-expansion-panel-header>
          <v-icon>mdi-clipboard-edit-outline</v-icon>
          <span class="ec_vendor_expander_title">Assistente di ordinazione</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-stepper vertical class="ec_home_stepper_container" v-model="selected_step">

            <v-stepper-step class="ec_home_stepper_step" :complete="selected_step > 1" step="1">

              <span class="ec_home_stepper_label"></span>
              <span class="ec_advise home">Potrai sempre modificare le tue scelte in seguito</span>

            </v-stepper-step>

            <v-stepper-content step="1" class="ec_stepper_content_wrapper">

              <div class="ec_stepper_content ONE">

                <div class="ec_stepper_guide">
                  Prepareremo il tuo ordine e lo renderemo disponibile per la consegna a domicilio o per il ritiro <strong>gratuito</strong> sul posto con appuntamento.
                </div>

                <h2>Cosa preferisci?</h2>
                <div class="ec_user_selection">
                  <v-btn outlined
                         color="#027d88"
                         v-for="method in shipping_methods"
                         :key="method.slug"
                         @click="selectShippingMethod(method)">
                    {{ method.name }}
                  </v-btn>
                </div>

              </div>

            </v-stepper-content>

            <v-stepper-step class="ec_home_stepper_step" :complete="selected_step > 2" step="2">
            <span class="ec_home_stepper_label">
              Seleziona la località
            </span>
            </v-stepper-step>

            <v-stepper-content step="2" class="ec_stepper_content_wrapper">

              <div class="ec_stepper_content TWO">

                <chips></chips>

                <h2 v-if="session.order_preferences.shipping_method == 'delivery'">Dove vuoi ricevere l'ordine?</h2>

                <h2 v-if="session.order_preferences.shipping_method == 'pickup'">Dove vuoi ritirare l'ordine?</h2>

                <div class="ec_user_selection">

                  <v-select
                      prepend-icon="mdi-map"
                      class="ec_ord_ass_select"
                      label="Scegli tra le città attualmente servite"
                      color="#027d88"
                      :items="vendor_search.available_cities"
                      v-model="session.order_preferences.city"
                      @change="selectCity($event)">
                  </v-select>

                </div>

              </div>

            </v-stepper-content>

            <v-stepper-step step="3" class="ec_home_stepper_step">
              <span class="ec_home_stepper_label">Orario desiderato</span>
            </v-stepper-step>

            <v-stepper-content step="3" class="ec_stepper_content_wrapper">

              <div class="ec_stepper_content THREE">

                <chips @deselect-shipping="deselectShipping()" @deselect-city="deselectCity()"></chips>

                <h2 v-if="session.order_preferences.shipping_method == 'delivery'">Quando vuoi ricevere il tuo ordine?</h2>

                <h2 v-if="session.order_preferences.shipping_method == 'pickup'">Quando vuoi ritirare il tuo ordine?</h2>

                <div class="ec_user_selection">

                  <datepicker :label="'Giorno'" :date="session.order_preferences.delivery_date" :dates="vendor_search.available_dates" @select="selectDate($event)"></datepicker>
                  <timepicker :label="'Orario'" @select="selectTime($event)" :times="vendor_search.available_times" :selected_time="session.order_preferences.delivery_time"></timepicker>

                </div>

              </div>

            </v-stepper-content>
          </v-stepper>

          <v-btn color="#027d88" depressed class="ec_see_results_button" @click="panel = null">Vedi risultati</v-btn>

        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

  </div>

</template>

<script>


import {mapState} from "vuex";
import datepicker from './infra/datepicker'
import timepicker from './infra/timepicker'
import chips from './infra/chips'

export default {

  name: 'StepSearch',
  components: {

    datepicker,
    timepicker,
    chips

  },
  computed: {

    ...mapState(['session','vendor_search']),


    selected_step: function(){

      let step = 1;

      if(this.session.order_preferences.shipping_method != null){step = 2;} else {return 1;}
      if(this.session.order_preferences.city != null){step = 3;} else {return 2;}

      return step;

    }



  },

  watch: {

    vendor_search: {
      handler: function(newValue){

        let self = this;



        if(self.session.order_preferences.city == null){return;}


        if(newValue.available_cities.findIndex(city => city == self.session.order_preferences.city) == -1){

          self.session.order_preferences.city = null;

        }


      },
      deep: true
    }

  },

  data() {
    return {
      e1: 1,
      panel: null,
      cities: [],
      shipping_methods: [

        {name: 'Ricevere a domicilio', slug: 'delivery'},
        {name: 'Ritirare gratis sul posto', slug: 'pickup'},

      ]
    }
  },

  mounted: function(){

    this.getLocations();

  },

  methods: {


    selectShippingMethod(method){


          this.session.order_preferences.shipping_method = method.slug;
          this.$store.dispatch('vendor_search/loadVendors');

    },



    selectCity(city){


      console.log('City ',city);
      this.e1=3;
      this.$store.dispatch('vendor_search/loadVendors');


    },



    selectTime(time){


      this.session.order_preferences.delivery_time = time;
      this.$store.dispatch('vendor_search/loadVendors');

    },

    selectDate(date){


      this.session.order_preferences.delivery_date = date;
      this.session.order_preferences.delivery_time = null;

      this.$store.dispatch('vendor_search/loadVendors');

    },

    getLocations() {

      this.$http.post('/api/erp/custom/magdel/location/cities').then((response) => {

        this.cities = response.data.payload;

      });

    },

  },

}

</script>

<style>

.ec_order_assistant_container {
  height: fit-content;
  position: absolute;
  width: 49%;
  right: 0;
  top: 35px;
  z-index: 2;
}

.ec_home_stepper_container {
  box-shadow: none;
  padding: 0;
}

.ec_stepper_content_wrapper {
  padding-top: 0 !important;
}

.ec_vendor_expander_title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 15px;
  text-align: right;
}

.ec_home_stepper_container h2 {
  padding: 0 0 10px;
}

.ec_stepper_guide {
  padding: 10px 0 15px;
}

.ec_stepper_content.ONE button {
  margin: 0 10px 10px 0;
}

.ec_advise.home {
  width: 100%;
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
}

.ec_see_results_button {
  margin: 10px 0 15px 59px;
  color: white !important;
}

@media (max-width: 600px) {
  .ec_order_assistant_container {
    position: fixed;
    top: 55px;
    left: 0;
    width: 100%;
  }
  .ec_stepper_guide {
    padding: 5px 0 10px 0;
  }
  .ec_home_stepper_container h2 {
    font-size: 16px;
  }
  .ec_home_stepper_step {
    padding: 5px 0 10px !important;
  }
  .ec_stepper_content {
    padding-bottom: 10px;
  }
  .ec_stepper_content_wrapper {
    margin: 0 !important;
    padding: 0 5px 0 15px !important;
    font-size: 14px !important;
  }
  .ec_stepper_content button {
    letter-spacing: .25px !important;
    font-size: 13px !important;
  }
  .ec_see_results_button {
    margin: 0 auto !important;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    border-radius: 0 !important;
    height: 55px !important;
  }
  .ec_user_selection .ec_ord_ass_select {
    padding-top: 0;
  }
  .ec_expansion_panel.opened {
    height: 100vh;
  }
}

</style>