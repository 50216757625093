/**
 * Vuex
 *
 * @library
 *
 * https://vuex.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import Vuex from 'vuex'

import * as session from './modules/session.js'

import * as vendor_search from './modules/vendor_search'
import * as menu from './modules/menu'
import * as cart from './modules/cart'
import * as backend from './modules/backend'

// Store functionality

Vue.use(Vuex)

// Create a new store
const store = new Vuex.Store({


  modules: {

    session,
    vendor_search,
    cart,
    menu,
    backend,

  },


});

export default store
