import ApiClient from '@/services/APIService.js'


export const namespaced = true;

export const state = {


    loading: true,
    saving: false,

    processing_cart_item: 0,
    processing_shipping_method: 0,
    processing_payment_method: 0,
    processing_timespan: null,


    show_errors: false,

    checking_out: false,

    shop: sessionStorage.getItem('shop'),
    list_id: sessionStorage.getItem('product_list'),

    order: [],

    payment_methods: [],
    shipping_methods: [],

    loading_countries: true,
    shipping_countries: [],
    served_cities: [],

    terms_accepted: false

}


export const mutations = {


    RESET(state){

        state.order = [];
        state.loading = false;
        state.shop = null;
        state.list_id = null;

    },

    SOFT_RESET(state){


        state.loading = false;
        state.shop = null;
        state.list_id = null;

    },

    SET_ORDER(state, order_data){

        state.order = order_data;

    }

}



export const getters = {


    bookableDays: (state) => {

        if(state.order.length == 0){return [];}

        return state.order.times.filter(day => day.times.filter(time => time.is_in_past == false).length > 0);

    },

    cartHasSlotsToday: (state) => {


        if(state.order.length == 0){return false;}

        let today = state.order.slots.filter(day => day.is_today == true);
        if(today.length == 0){return false;}

        let times = today[0].times.filter(time => time.is_in_past == false);

        if(times.length == 0){return false;}

        return true;




    },

    getShippingMethod: (state) => {

        if(state.order.shipping.length == 0){

            return false;

        } else {


            return state.order.shipping[0];


        }

    },

    allowOrders: (state) => {


        if(state.order.length === 0){return false;}

        if(state.order.slots.length === 0){return false;}

        return true;

    }


}

export const actions = {


    init({state, dispatch,commit}, data){


        state.loading = true;

        commit('SOFT_RESET');

        state.shop = data.shop;
        state.list_id = data.list_id;

        sessionStorage.setItem('shop',state.shop);
        sessionStorage.setItem('product_list',state.list_id);

        dispatch('loadCart',true);

    },

    init_by_token({state},token){


        state.is_loading = true;


        console.log('public token: ',token);

        state.is_loading = false;

    },

    place_order({state}){


        if(state.terms_accepted != true){return;}

        state.loading = true;


        state.checking_out = true;


        ApiClient.post('pos/v1/checkout/'+state.order.id+'/place', {}).then((response) => {

            state.order = response.data.payload;
            state.loading = false;
            state.checking_out = false;

        });


    },


    reset({commit}){

        commit('RESET');

    },

    loadCart({state}, reload = false){


        if(reload == false){

            state.loading = true;
        }


        console.log('Reload Cart: ',state.list_id);


        ApiClient.post('pos/v1/'+state.shop+'/cart', {id: state.list_id}).then((response) => {


            state.order = response.data.payload;
            state.loading = false;

        });

    },


    loadCartWithToken({state}, token){

        state.loading = true;

        ApiClient.get('pos/v1/checkout/'+token+'/status').then((response) => {


            state.order = response.data.payload;
            state.loading = false;

        });



    },


    loadShippingCountries({state}){



        state.loading_countries = true;

        ApiClient.get('/api/erp/v2/locations/shop-countries/'+state.shop).then((response) => {


            let countries = response.data.payload;

            let data = [];

            for (const [key, value] of Object.entries(countries.shipping)) {

                data.push({value: key, text:value})


            }




            state.shipping_countries = data;
            state.loading_countries = false;


        });


    },


    loadServedCities({state}){


        ApiClient.post('/api/erp/custom/magdel/location/cities/'+state.shop,{}).then((response) => {


            state.served_cities = response.data.payload;


        });


    },


    add({state, commit}, data){


        state.processing_cart_item = data.product_id;

        data.list_id = state.list_id;


        ApiClient.post('pos/v1/'+state.shop+'/cart/add', data).then((response) => {


            commit('SET_ORDER',response.data.payload);
            state.processing_cart_item = null;


        });

    },

    updateItemQuantity({state, dispatch, commit}, data){

        data.list_id = state.list_id;
        state.saving = true;

        ApiClient.post('pos/v1/'+state.shop+'/cart/update', data).then((response) => {

            state.saving = false;

            if(response.data.payload != false){

                commit('SET_ORDER',response.data.payload);
            }

            dispatch('getMethods');

        });


    },

    updateItemNote({state},data){


        data.list_id = state.list_id;
        state.saving = true;

        ApiClient.post('pos/v1/'+state.shop+'/cart/update', data).then((response) => {


            state.saving = false;
            console.log('Update Item Note Response: ',response);

        });


    },

    removeItem({state, commit},data){

        data.list_id = state.list_id;
        state.saving = true;

        ApiClient.post('pos/v1/'+state.shop+'/cart/remove', data).then((response) => {


            state.saving = false;
            commit('SET_ORDER',response.data.payload);



        });


    },

    updateAddress({state,commit}, data){

        state.saving = true;

        ApiClient.post('pos/v1/checkout/'+state.order.id, data).then((response) => {


            commit('SET_ORDER',response.data.payload);
            state.saving = false;


        });

    },

    updateDesiredDelivery({state,commit},data){

        state.saving = true;




        let payload = {

            desired_delivery_time_from: data.from,
            desired_delivery_time_to: data.to,

        };


        state.processing_timespan = payload;

        ApiClient.post('pos/v1/checkout/'+state.order.id, payload).then((response) => {

            state.saving = false;
            commit('SET_ORDER',response.data.payload);
            state.processing_timespan = null;


        });


    },

    updateOrder({state,commit}){

        state.is_saving = true;



        let payload = {

            payment: state.order.payment,
            shipping: state.order.shipping,
            shipping_address: state.order.shipping_address,
            billing_address: state.order.billing_address,
            desired_delivery_time_from: state.order.desired_delivery_time_from,
            desired_delivery_time_to: state.order.desired_delivery_time_to,

        };


        console.log(payload);


        ApiClient.post('pos/v1/checkout/'+state.order.id, payload).then((response) => {

            state.saving = false;
            commit('SET_ORDER',response.data.payload);
            console.log('Update Order', response);

        });


    },

    selectShippingMethod({state,commit}, method){


        state.is_saving = true;

        let data = {
            shipping_method: method.id
        }


        state.processing_shipping_method = method.id;

        ApiClient.post('pos/v1/checkout/'+state.order.id, data).then((response) => {


            state.saving = false;
            console.log('Shipping method response: ',response);
            commit('SET_ORDER',response.data.payload);

            state.processing_shipping_method = 0;

        });


    },


    selectPaymentMethod({state,commit}, method){


        state.is_saving = true;

        let data = {


            payment_method: method.id

        }

        state.processing_payment_method = method.id;

        ApiClient.post('pos/v1/checkout/'+state.order.id, data).then((response) => {


            state.saving = false;
            console.log('Payment method response: ',response);
            commit('SET_ORDER',response.data.payload);
            state.processing_payment_method = 0;

        });


    },

    getMethods({state}){



        ApiClient.get('pos/v1/checkout/'+state.order.id).then((response) => {

            state.shipping_methods = response.data.payload.shipping_methods;
            state.payment_methods = response.data.payload.payment_methods;

            state.saving = false;

        });


    }
}