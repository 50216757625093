<template>

  <div class="ec_chips_wrapper" style="overflow-x: scroll;">

    <div class="ec_chips_container">

      <v-chip outlined class="ec_chip" close v-if="showChip('shipping-method') && session.order_preferences.shipping_method != null" @click:close="deselectShipping()">
        <span v-if="session.order_preferences.shipping_method == 'delivery'">Consegna a domicilio</span>
        <span v-if="session.order_preferences.shipping_method == 'pickup'">Ritiro gratuito</span>
      </v-chip>

      <v-chip outlined class="ec_chip" close v-if="showChip('city') && session.order_preferences.city != null" @click:close="deselectCity()">{{ session.order_preferences.city }}</v-chip>

      <v-chip outlined class="ec_chip" close v-if="showChip('date') && session.order_preferences.delivery_date != null" @click:close="deselectDate()">{{session.order_preferences.delivery_date | moment('DD.MM.Y')}}</v-chip>
      <v-chip outlined class="ec_chip" close v-if="showChip('time') && session.order_preferences.delivery_time != null" @click:close="deselectTime()">{{session.order_preferences.delivery_time}}</v-chip>

    </div>
  </div>

</template>


<script>

import {mapState} from "vuex";

export default {

  name: 'StepSearchChips',
  props: {
    available_chips: {
      default: function () {
        return ['shipping-method', 'city'];
      }
    },
  },
  methods: {


    showChip(type){


      if(this.available_chips.findIndex(m => m === type) !== -1){return true;}

      return false;

    },

    deselectShipping(){
      this.session.order_preferences.shipping_method = null;
      this.$store.dispatch('vendor_search/loadVendors');
    },

    deselectCity(){
      this.session.order_preferences.city = null;
      this.$store.dispatch('vendor_search/loadVendors');

    },

    deselectDate(){

      this.session.order_preferences.delivery_date = null;
      this.session.order_preferences.delivery_time = null;
      this.$store.dispatch('vendor_search/loadVendors');

    },


    deselectTime(){

      this.session.order_preferences.delivery_time = null;
      this.$store.dispatch('vendor_search/loadVendors');

    }

  },
  computed: {

    ...mapState(['session','vendor_search']),

  }

}

</script>

<style>

.ec_chips_wrapper::-webkit-scrollbar {
  display: none;
}

.ec_chips_container {
  margin-top: 10px;
}
.ec_chip {
  margin: 0 5px 15px 0;
  width: fit-content;
  border-radius: 8px !important;
}

.ec_carousel_chips .ec_chip {
  margin: 10px 5px 0 0;
  padding: 20px;
  font-weight: bold;
  border-color: #cccccc !important;
}

@media (max-width: 600px) {
  .ec_chips_container {
    margin: 0 5px 5px 0;
    display: inline-flex;
  }
  .ec_chip {
    margin: 0 4px 6px 0;
    font-size: 12px !important;
  }
}



</style>