<template>

  <v-card flat :loading="vendor_search.loading_vendors">

    <div class="general ShopCarousel">

      <div class="ec_home_container">

        <div class="ec_carousel_nolocation" v-if="session.location == false">
          Per favore abilita i servizi di geolocalizzazione per vedere i venditori a te vicini.
          <v-btn>Attiva i servizi di geolocalizzazione</v-btn>
        </div>

      </div>

      <div class="ec_home_container" v-if="vendor_search.loaded_vendors == true && vendor_search.vendors.length == 0">

        <v-alert class="ec_novendors_found_alert" type="warning" color="amber lighten-4">
          Nessun venditore disponibile per i criteri impostati
        </v-alert>

        <v-btn class="ec_resetsearch_button" large outlined color="#027d88" style="margin-top: 20px" @click="resetSearch()">Ricomincia la ricerca</v-btn>

      </div>

      <div v-else>

        <div class="ec_full_grey">
          <div class="ec_home_container">

            <div class="ec_carousel_header">
              <h3>
                <v-icon>mdi-cursor-default-click</v-icon>
                Inizia ora il tuo ordine
              </h3>

              <v-alert class="ec_alert_shadowed" color="amber lighten-4" style="margin: 15px 0 5px !important;" dismissible>
                Puoi cliccare sui venditori elencati o aprire l'assistente di ordinazione per farti guidare nella scelta.
              </v-alert>

              <chips class="ec_carousel_chips" @deselect-shipping="deselectShipping()" @deselect-city="deselectCity()" :available_chips="['time','date','shipping-method','city']"></chips>

            </div>

            <div class="ec_carousel_grid_container">

              <div class="ec_carousel_tile_container" v-for="vendor in vendor_search.vendors" :key="vendor.id">


                <router-link :to="{ name: 'restaurant', params: { slug: vendor.slug }}">

                  <v-card class="ec_carousel_tile">

                    <v-img class="ec_vendtile_pic" :aspect-ratio="1/1" v-if="vendor.logo !== false" :src="vendor.logo" max-width="300"></v-img>
                    <v-img class="ec_vendtile_pic" v-else :src="require('@/assets/placeholder.png')" max-width="300"></v-img>

                    <div class="ec_vendor_tile_info_container">

                      <span class="ec_vendor_tile_title">{{vendor.name}}</span>

                      <span class="ec_vendor_tile_subtitle">{{vendor.claim}}</span>

                    </div>



                      <shop-meta :vendor="vendor"></shop-meta>




                  </v-card>
                </router-link>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-card>

</template>

<script>


import { mapState } from 'vuex';
import chips from "@/components_frontend/Home/StepSearch/infra/chips";
import ShopMeta from './infra/ShopMeta'


export default {
  name: 'ShopCarousel',
  components: {
    chips,
    ShopMeta
  },
  computed: {

    ...mapState(['session']),
    ...mapState(['vendor_search'])

  },

  data() {
    return {
      e1: 1,
      cities: [],
      shipping_methods: [

        {name: 'Home delivery', slug: 'delivery'},
        {name: 'Takeaway', slug: 'pickup'},

      ]
    }
  },

  mounted: function(){

    this.getLocations();


    if(this.session.supports_geolocation == true && this.session.location != false){
      this.$store.dispatch('vendor_search/init');
    }
    this.$store.dispatch('vendor_search/init');

  },


  methods: {

    selectShippingMethod(method){


      this.session.order_preferences.shipping_method = method.slug;


      if(this.session.order_preferences.city != null){
        this.e1=3
      } else {

        this.e1=2;

      }
      this.$store.dispatch('vendor_search/loadVendors');

    },

    deselectShipping(){

      this.session.order_preferences.shipping_method = null;
      this.e1=1;
      this.$store.dispatch('vendor_search/loadVendors');

    },

    selectCity(city){


      console.log('City ',city);
      this.e1=3;
      this.$store.dispatch('vendor_search/loadVendors');


    },

    deselectCity(){

      this.session.order_preferences.city = null;
      this.e1=2;
      this.$store.dispatch('vendor_search/loadVendors');

    },


    selectTime(time){


      this.session.order_preferences.delivery_time = time;
      this.$store.dispatch('vendor_search/loadVendors');

    },

    selectDate(date){


      this.session.order_preferences.delivery_date = date;
      this.session.order_preferences.delivery_time = null;

      this.$store.dispatch('vendor_search/loadVendors');

    },

    getLocations() {

      this.$http.post('/api/erp/custom/magdel/location/cities').then((response) => {

        this.cities = response.data.payload;

      });

    },

    resetSearch(){

      this.$store.dispatch('session/resetSearchPreferences');
      this.$store.dispatch('vendor_search/loadVendors');

    }

  },

  watch: {


    session: {

      handler: function(newValue, oldValue){

        console.log('Session Change: ',newValue, oldValue);
        this.$store.dispatch('vendor_search/init');

      },
      deep: true

    },

  }



}

</script>

<style>

.ec_home_container {
  max-width: 1000px;
  margin: 0 auto;
}

.ec_carousel_header {
  padding: 20px 0 0;
}

.ec_carousel_grid_container {
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  grid-column-gap: 1.33%;
  grid-row-gap: 16px;
  padding: 20px 0 70px 0;
}

.ec_carousel_tile {
  transition: all .4s;
  border-bottom: 3px solid white;
}

.ec_carousel_tile_container a {
  text-decoration: none;
}

.ec_carousel_tile:hover {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.26), 0 2px 2px 0 rgba(0, 0, 0, 0.27), 0 1px 5px 0 rgba(0, 0, 0, 0.22) !important;
}

.ec_carousel_tile_container:hover .ec_carousel_tile {
  border-bottom: 3px solid #027d88;
}

.ec_vendor_tile_info_container {
  padding: 5px 15px 0;
}

.ec_vendor_tile_title {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  display: flex;
}

.ec_vendor_tile_subtitle {
  font-size: 16px;
  min-height: 50px;
  display: flex;
  width: 100%;
}

.ec_novendors_found_alert,
.ec_novendors_found_alert i {
  color: rgba(0, 0, 0, 0.87) !important;
}


@media (max-width: 600px) {
  .ec_carousel_header {
    padding: 15px 10px 10px;
  }
  .ec_carousel_header h3 {
    padding: 0;
  }
  .ec_carousel_chips {
    overflow-x: scroll;
    display: flex;
  }
  .ec_carousel_grid_container{
    grid-template-columns: 100%;
    grid-column-gap: 0;
    grid-row-gap: 10px;
    padding: 0;
  }
  .ec_carousel_tile_container {
   padding: 0 10px;
  }
  .ec_carousel_tile {
    border-bottom: none;
    display: grid;
    grid-template-columns: 20% 60% 20%;
    grid-column-gap: 0;
    grid-template-rows: auto auto auto;
    padding: 10px;
  }
  .ec_vendor_tile_title {
    padding-top: 5px;
    font-size: 18px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ec_vendor_tile_subtitle {
    font-size: 14px;
  }
  .ec_vendtile_pic {
    grid-row: 1;
    grid-column: 2;
    border-radius: 0 !important;
  }
  .ec_vendor_tile_subtitle {
    min-height: auto;
  }
  .ec_vendor_tile_info_container {
    padding: 0;
    grid-row: 2;
    grid-column: 1 / span 3;
  }
  .ec_resetsearch_button {
    width: 100%;
    margin-top: 0 !important;
    background-color: #027d88;
    color: white !important;
    border: none !important;
    border-radius: 0 !important;
  }
}

</style>