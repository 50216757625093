import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/frontend/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },

    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/backend/Login.vue')
    },

    {
        path: '/backend',
        name: 'backend',
        component: () => import(/* webpackChunkName: "vendor-backend" */ '../views/backend/Start.vue'),
        meta: { requiresAuth: true}
    },

    {
        path: '/backend/:vendor/screen',
        name: 'vendor-screen',
        component: () => import(/* webpackChunkName: "vendor-screen" */ '../views/backend/Start.vue'),
        meta: { requiresAuth: true, hide_default_navigation: true, view: 'single_screen' }
    },


    {
        path: '/faq',
        name: 'faq',
        component: () => import(/* webpackChunkName: "static-faq" */ '../views/static/FAQ.vue')
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import(/* webpackChunkName: "static-faq" */ '../views/static/Contact.vue')
    },
    {
        path: '/vendor-portal',
        name: 'vendor-portal',
        component: () => import(/* webpackChunkName: "static-faq" */ '../views/static/VendorPortal.vue')
    },
    {
        path: '/terms',
        name: 'terms',
        component: () => import(/* webpackChunkName: "static-faq" */ '../views/static/Terms.vue')
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () => import(/* webpackChunkName: "static-faq" */ '../views/static/Privacy.vue')
    },
    {
        path: '/cookies',
        name: 'cookies',
        component: () => import(/* webpackChunkName: "static-faq" */ '../views/static/Cookies.vue')
    },
    {
        path: '/corporate',
        name: 'corporate',
        component: () => import(/* webpackChunkName: "static-faq" */ '../views/static/Corporate.vue')
    },
    {
        path: '/social',
        name: 'social',
        component: () => import(/* webpackChunkName: "static-faq" */ '../views/static/SocialMedia.vue')
    },
    {
        path: '/jobs',
        name: 'jobs',
        component: () => import(/* webpackChunkName: "static-faq" */ '../views/static/Jobs.vue')
    },
    {
        path: '/register-as-vendor',
        name: 'register-as-vendor',
        component: () => import(/* webpackChunkName: "static-faq" */ '../views/static/RegisterAsVendor.vue')
    },


    /*  STATIC PAGES NEED TO BE PLACED ABOVE THIS LINE FOR THE ROUTING TO WORK    */


    {
        path: '/:slug',
        name: 'restaurant',
        component: () => import(/* webpackChunkName: "restaurant" */ '../views/frontend/Restaurant.vue')
    },
    {
        path: '/:slug/menu/:id',
        name: 'restaurant',
        component: () => import(/* webpackChunkName: "restaurant" */ '../views/frontend/RestaurantMenuRedirect.vue')
    },
    {
        path: '/:slug/checkout',
        name: 'checkout',
        component: () => import(/* webpackChunkName: "restaurant" */ '../views/frontend/RestaurantCheckout.vue')
    },
    {
        path: '/:slug/checkout/confirm/:public_token',
        name: 'confirmed',
        component: () => import(/* webpackChunkName: "restaurant" */ '../views/frontend/OrderConfirmed.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
    routes
})

import Meta from 'vue-meta'

Vue.use(Meta,{
    keyName: 'metaInfo', // the component option name that vue-meta looks for meta info on.
    attribute: 'data-vue-meta', // the attribute name vue-meta adds to the tags it observes
    ssrAttribute: 'data-vue-meta-server-rendered', // the attribute name that lets vue-meta know that meta info has already been server-rendered
    tagIDKeyName: 'vmid' // the property name that vue-meta uses to determine whether to overwrite or append a tag
})

export default router
