import ApiClient from '@/services/APIService.js'



export const namespaced = true;

export const state = {

    shop: null,


    loading_lists: false,

    lists: [],
    selected_list: null,

    loading_products: false,
    products: [],

    categories: null,
    selected_category: null,

}


export const mutations = {


    RESET(state){

        state.shop = null;
        state.products = [];
        state.selected_list = null;

    },

    SET_LISTS(state,value){

        state.lists = value;


    },

    SET_PRODUCTS(state,value){

        state.products = value;

    },

    SET_CATEGORIES(state,value){

        state.selected_category = value[0].slug;
        state.categories = value;

    },

}



export const getters = {


    getCategories: (state) => {

        let categories = [];

        for(let i=0; i < state.products.length; i++){

            for(let j =0; j < state.products[i].categories.length; j++){

                let index = categories.find(cat => cat.id === state.products[i].categories[j].id);

                if(index == undefined){
                    categories.push(state.products[i].categories[j]);
                }

            }

        }

        return categories.sort(function(a,b){return a.menu_order - b.menu_order;});

    }

}

export const actions = {


    init({dispatch, state},shop){


        console.log('Init menu for shop',shop);
        state.shop = shop;
        dispatch('loadLists');


    },

    loadLists({commit, state, dispatch}){

        state.loading_lists = true;

        let preselected_list = parseInt(sessionStorage.getItem(state.shop+'::selected_menu'));

        ApiClient.get('pos/v1/'+state.shop+'/lists').then((response) => {

            commit('SET_LISTS',response.data.payload);


            if(response.data.payload.length > 0){

                let index = response.data.payload.findIndex(c => c.id === preselected_list);

                if(index === -1){

                    dispatch('selectList', response.data.payload[0]);

                } else {

                    dispatch('selectList', response.data.payload[index]);

                }


                state.loading_lists = false;

            }

        });

    },


    selectList({commit, state, dispatch}, list){

        state.selected_list = list;
        state.loading_products = true;


        sessionStorage.setItem(state.shop+'::selected_menu',list.id);

        ApiClient.post('pos/v1/'+state.shop+'/product-list',{id: list.id}).then((response) => {

            commit('SET_PRODUCTS',response.data.payload.products);

            dispatch('cart/init',{shop: state.shop, list_id: list.id}, {root:true});

            state.loading_products = false;

        });

    },


    selectCategory({state},category){


        if(category == null){
            state.selected_category = null;
        } else {
            state.selected_category = category.slug;
        }

    },

}