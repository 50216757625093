import ApiClient from '@/services/APIService.js'


export const namespaced = true;

export const state = {

    loaded_vendors: false,
    loading_vendors: false,
    vendors: [],

    available_times: [],
    available_dates: [],
    available_cities: [],

    pagination: {
        total_results: 0,
        possible_results: 0,
        page: 0
    }

}


export const mutations = {

    SET_VENDORS(state, data){

        state.vendors = data.results.sort((a, b) => a.weight - b.weight);


    },


    SET_TIMES(state,data){


        state.available_times = data.selectable_times;

    },
    
    SET_DATES(state,data){

        state.available_dates = data.selectable_dates;

    },

    SET_CITIES(state,data){

        state.available_cities = data.selectable_cities;

    },

    SET_PAGINATION(state, data){


        state.pagination = {


            returned_results: data.returned_results,
            possible_results: data.possible_results,
            page: data.page,

        };


    }

}



export const getters = {



}

export const actions = {



    init({dispatch}){

        dispatch('loadVendors');

    },




    loadVendors({commit, rootGetters, state}){

        state.loading_vendors = true;

        let params = {

            proximity: rootGetters['session/getCurrentLocation'],

        };


        let preferences = rootGetters['session/getOrderPreferences']


        if(preferences.city !== null){
            params.location = {location_code: preferences.city, location_type: 'city'};
        }

        if(preferences.shipping_method != null){
            params.shipping_method = preferences.shipping_method;
        }


        params.delivery_date = preferences.delivery_date;
        params.delivery_time = preferences.delivery_time;



        ApiClient.post('/api/erp/custom/magdel/location/vendors', params).then((response) => {

            commit('SET_VENDORS',response.data.payload);
            commit('SET_PAGINATION',response.data.payload);

            if(preferences.delivery_date == null){

                commit('SET_DATES',response.data.payload);

            }
            if(preferences.delivery_time == null){

                commit('SET_TIMES',response.data.payload);

            }

            commit('SET_CITIES',response.data.payload);

            state.loaded_vendors = true;
            state.loading_vendors = false;

        });


    }


}