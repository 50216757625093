<template>

  <div class="ec_vendor_tile_details_container">

    <!--<pre>Available menus: {{vendor.menu_count}}</pre>-->

    <!--
    <pre>Serving Today: {{isAvailableToday}}</pre>
    <pre>Serving Now: {{isServingNow}}</pre>
    <pre>Has Preorders: {{hasPreorders}}</pre>
    -->

    <div class="ec_vendtile_absolute_labels" v-if="nextServedTime !== false && isAvailableToday">

      <span class="ec_vendtile_label time">
        {{nextServedTime.from_time}} - {{nextServedTime.to_time}}
      </span>

    </div>


    <!--
    <pre>{{isAvailableToday}}</pre>
    <pre>{{isServingNow}}</pre>
    -->

    <div class="ec_vendtile_label closed" v-if="!isAvailableToday && !hasPreorders">
      Presto disponibile
    </div>

    <div class="ec_vendor_tile_meta" v-else>

      <div v-if="isServingNow" class="ec_vendtile_absolute_labels">

        <span class="ec_vendtile_label now_serving">Aperto</span>

      </div>

      <div v-else-if="isAvailableToday" class="ec_vendtile_absolute_labels">

        <span class="ec_vendtile_label preorder">Prenota per oggi</span>

      </div>

      <div v-else-if="hasPreorders" class="ec_vendtile_absolute_labels">

        <span class="ec_vendtile_label preorder">Preordina</span>

      </div>

      <div v-if="availableShippingMethods.length == 0">
        Nessuna disponibilità
      </div>

      <div class="ec_vendtile_deliverymethods_container" v-else>
        <div class="ec_relative" v-for="method in availableShippingMethods" :key="method">

          <v-tooltip bottom v-if="method == 'pickup' && session.client_type == 'desktop'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-clock-check-outline</v-icon>
            </template>
            <span class="ec_vendtile_tooltip takeaway">
              Salta la coda! Ritiro gratuito<br> su appuntamento a {{vendor.base_city}}
            </span>
          </v-tooltip>

          <span class="ec_vendtile_infospan takeaway mobile" v-if="method == 'pickup' && session.client_type == 'mobile'">
            <v-icon v-bind="attrs" v-on="on">mdi-clock-check-outline</v-icon>
            Salta la coda: ritira gratis su appuntamento
          </span>

          <v-tooltip bottom v-if="method == 'delivery' && session.client_type == 'desktop'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-truck-check</v-icon>
            </template>
            <span class="ec_vendtile_tooltip delivery">
              Consegna a domicilio
            </span>
          </v-tooltip>

          <span class="ec_vendtile_infospan delivery mobile" v-if="method == 'delivery' && session.client_type == 'mobile'">
            <v-icon>mdi-truck-check</v-icon>
            A domicilio
          </span>

        </div>
      </div>
    </div>

  </div>

</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'ShopMeta',
  components: {},
  computed: {

    ...mapState(['session']),

    futureSlots(){


      return this.vendor.slots.filter(slot => slot.is_today == false);



    },

    todaySlots(){


      return this.vendor.slots.filter(slot => slot.is_today == true);


    },

    todayTimes(){



      let slots = this.todaySlots;


      let all_times = [];



      for(let i = 0; i < slots.length; i++){


        let times = slots[i].times.filter(time => time.is_in_past == false);


        if(times.length > 0){


          for(let j = 0; j < times.length; j++){


            all_times.push(times[j])


          }

        }



      }




      return all_times;





    },

    nextServedTime(){


      let times = this.todayTimes;
      if(times.length == 0){return false;}
      return times[0];



    },

    isAvailableToday(){


      let slots = this.todaySlots;

      for(let i = 0; i< slots.length; i++){

        let times = slots[i].times.filter(time => time.is_in_past == false);
        if(times.length != 0){return true;}

      }

      return false;

    },

    isServingNow(){

      let self = this;


      if(self.isAvailableToday == false){return false;}

      let slots = self.todaySlots;

      for(let i = 0; i< slots.length; i++){

        let times = slots[i].times;//.filter(time => time.is_in_past == false);


       // return times;


        if(times.length > 0){

          for(let j = 0; j < times.length; j++){



            if(

                    self.$moment(times[j].date_iso+' '+times[j].from_time) <= self.$moment() &&
                    self.$moment(times[j].date_iso+' '+times[j].to_time) >= self.$moment()

            ){


              return true;

            }



          }

        }



      }

      return false;

    },

    availableShippingMethods(){

      let self = this;

      if(self.vendor.shipping_methods.length == 0){return [];}

      let methods = [];
      self.vendor.shipping_methods.forEach(element => {

        if(element.erp_active == true){
          let index = methods.findIndex(m => m === element.type);
          if(index == -1){
            methods.push(element.type);
          }
        }

      });

      return methods;


    },

    hasPreorders(){

      if(this.isAvailableToday == true){return true;}

      let future_slots = this.futureSlots;


      if(future_slots.length == 0){return false;}


      for(let i = 0; i < future_slots.length; i++){


        let times = future_slots[i].times.filter(time => time.is_in_past == false);


        if(times.length > 0){return true;}


      }


      return false;

    },



  },
  props: {
    vendor: {
      default: function () {
        return false;
      }
    },
  },
  data() {return {




  }},






}

</script>

<style>

.ec_vendor_tile_details_container {
  padding: 13px 15px 10px;
  border-top: 1px solid #dadada;
  margin-top: 5px;
}

.ec_vendtile_label {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.ec_vendtile_deliverymethods_container {
  display: flex;
  justify-content: center;
}

.ec_vendtile_deliverymethods_container div {
  display: inline-flex;
  justify-content: center;
  width: 50%;
  align-items: center;
}

.ec_carousel_tile:hover i {
  color: #027d88 !important;
}

.ec_vendtile_absolute_labels .ec_vendtile_label {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 3px 12px;
  font-size: 13px;
  text-transform: uppercase;
  border-radius: 5px;
  background-color: white;
  color: #027d88;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.ec_vendtile_absolute_labels .ec_vendtile_label.time {
  top: 40px;
  color: #252525;
}

.v-tooltip__content {
  margin-top: -5px;
  text-align: center;
  background-color: #585858 !important;
  opacity: 1 !important;
}

@media (max-width: 600px) {
  .ec_vendor_tile_details_container {
    grid-column: 1 / span 3;
    grid-row: 3;
    padding: 0;
    border: none;
    margin: 0;
  }

  .ec_vendtile_deliverymethods_container {
    display: block;
    padding-top: 10px;
  }

  .ec_vendtile_deliverymethods_container div {
    display: block;
    width: 100%;
  }

  .ec_vendtile_infospan {
    display: block;
    padding: 0 0 5px 30px;
    font-size: 13px;
    position: relative;
    width: 100%;
  }

  .ec_vendtile_infospan i {
    position: absolute !important;
    left: 0;
    font-size: 20px !important;
  }

  .ec_vendtile_label {
    font-size: 13px !important;
  }
}
</style>