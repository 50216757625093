<template>

  <div class="general home">

    <div class="ec_full_white">

      <div class="ec_page_container two_columns">
        <div class="ec_hmb_inside">
          <h1 class="ec_hmb_headline">Per una nuova esperienza <br>di ordinazione digitale</h1>
          <h2 class="ec_hmb_subhead">PRENOTAZIONE<span class="sep"> • </span>TAKE AWAY<span class="sep"> • </span>DELIVERY</h2>
        </div>
        <step-search></step-search>
      </div>


      <!--
      <a href="#"><img :src="require('@/assets/shop-placeholder.png')"/></a>
      -->


      <shop-carousel></shop-carousel>

      <div class="ec_page_container">

        <div class="ec_page_subcontainer">

        <h3 class="ec_hmb_headline">Le nuove adesioni sono aperte!</h3>

          <div class="ec_home_vendor_subscription_button_container">

            <v-btn outlined large color="#027d88" @click="$refs.subscribe_vendor.openDialog()">
              <v-icon style="padding-right: 10px">mdi-puzzle-plus</v-icon>
              AGGIUNGI IL TUO NEGOZIO
            </v-btn>

            <subscribe-as-vendor-dialog ref="subscribe_vendor"></subscribe-as-vendor-dialog>

          </div>

          <span>È attualmente in corso l'inserimento dei nuovi venditori. Inizia subito a ricevere prenotazioni per la tua attività.<strong> Non lasciare che il tuo sia il pezzo mancante!</strong></span>

        </div>

      </div>

      <div class="ec_page_container home_tiles">

        <div class="ec_home_tile_container">
          <div class="ec_home_tile">
            <h2 class="ec_home_tile_header">Innovazione</h2>
            <span class="ec_home_tile_content">

              <p>
                Il primo sistema per la gestione completa di ordini e prenotazioni digitali aperto a ristoranti e venditori di prodotti alimentari.
              </p>

              <ul>
                <li>Consegna a domicilio</li>
                <li>Ritiro gratuito</li>
                <li>Prenotazione del tavolo</li>
              </ul>

        </span>
          </div>
        </div>

        <div class="ec_home_tile_container">
          <div class="ec_home_tile">
            <h2 class="ec_home_tile_header">Qualità</h2>
            <span class="ec_home_tile_content">

              <p>
                Selezioniamo solo le migliori proposte del territorio in cui operiamo, garantendo gli alti livelli di qualità dei nostri partner esclusivi.
              </p>
              <p>
                I venditori presenti sulla piattaforma accettano ordinazioni online solo con magentadelivery.it
              </p>

        </span>
          </div>
        </div>

        <div class="ec_home_tile_container">
          <div class="ec_home_tile">
            <h2 class="ec_home_tile_header">Supporto</h2>
            <span class="ec_home_tile_content">
              <p>
              Acquistare con magentadelivery è l'occasione concreta di sostenere gli esercenti locali.
              </p>
              <p>Offriamo le migliori condizioni sul mercato a parità di servizio offerto.</p>
        </span>
          </div>
        </div>

      </div>

    </div>

  </div>


</template>

<script>

import ShopCarousel from '../../components_frontend/Home/ShopCarousel/ShopCarousel'
import StepSearch from '../../components_frontend/Home/StepSearch/StepSearch'
import SubscribeAsVendorDialog from '../../components_frontend/Home/subscribe_as_vendor_dialog'
import { mapState } from 'vuex';

export default {
  name: 'Home',
  metaInfo () {
    return {
      title: 'Prenotazione digitale • Asporto su appuntamento • Consegna a domicilio | Magenta Delivery',
      meta: [
        {property: 'og:image', content:  location.protocol + '//' + location.hostname+require('@/assets/social/facebook_image.png')},
      ]
    }
  },
  components: {

    ShopCarousel,
    StepSearch,
    SubscribeAsVendorDialog

  },
  computed: {

    ...mapState(['session'])

  }

}

</script>

<style>

.ec_hmb_headline {
  font-size: 32px;
  line-height: 1.2;
}

.ec_hmb_subhead {
  font-size: 18px;
  line-height: 1.2;
  padding-top: 15px;
  color: #da3856 !important;
  letter-spacing: 1px;
  font-weight: normal;
}

.sep {
  color: #cccccc !important;
}

.ec_page_container.two_columns {
  display: grid;
  grid-template-columns: 49% 49%;
  grid-column-gap: 2%;
  width: 100%;
  position: relative;
}

.ec_page_container.home_tiles {
  display: grid;
  grid-template-columns: 31.11% 31.11% 31.11%;
  grid-column-gap: 3.33%;
  padding-top: 0;
}

.ec_home_tile_container {
  text-align: left;
  padding-bottom: 30px;
}

h2.ec_home_tile_header {
  color: #da3856;
  padding-bottom: 10px;
}

.ec_home_vendor_subscription_button_container {
  margin: 15px 0 20px;
}

@media (max-width: 600px) {
  .ec_page_container.home_tiles {
    grid-template-columns: 100%;
    grid-column-gap: 0;
    padding: 0 15px 0;
  }

  .ec_page_container.two_columns {
    grid-template-columns: 100%;
    grid-row-gap: 10px;
    padding: 65px 10px 0;
  }

  .ec_home_tile_container {
    padding: 0 0 15px;
  }

  .ec_home_tile_header {
    font-size: 18px;
    padding-bottom: 5px !important;
  }

  .ec_home_tile_content {
    font-size: 14px;
  }

  .ec_home_tile_content p,
  .ec_home_tile_content ul {
    margin-bottom: 5px;
  }

  .ec_hmb_inside {
    padding: 10px 5px;
  }

  .ec_hmb_headline {
    font-size: 22px;
  }

  .ec_hmb_subhead {
    font-size: 13px;
    line-height: 1.5;
  }

  .ec_home_vendor_subscription_button_container button {
    width: 100% !important;
  }

  .ec_page_subcontainer {
    padding-top: 10px;
  }
}

</style>