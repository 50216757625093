<template>

  <div>

    <footer>

      <cookie-law class="ec_cookielaw" v-on:accept="onAccept()">

        <div class="ec_props" slot-scope="props">

          <v-icon @click="props.accept" color="white" class="close_cookies">mdi-close-circle</v-icon>
          <span>Utilizziamo cookie per migliorare l'esperienza di navigazione. <router-link class="more_cookies" to="privacy">Scopri di più</router-link></span>

        </div>

      </cookie-law>
    </footer>

  </div>


</template>

<script>
import CookieLaw from 'vue-cookie-law'
import Vue from "vue";
import VueGtag from "vue-gtag";
import { bootstrap } from 'vue-gtag'

Vue.use(VueGtag, {
  config: { id: "G-71ZKT6S4T7"},
  bootstrap: false

});

export default {
  name: 'cookie_notice',
  components: { CookieLaw },

  mounted: function(){

    // Enable Tracking as user is not asked for permission after style changes anyway.
    this.enableTracking();

  },
  methods: {

    onAccept(){
      //this.enableTracking();
    },

    enableTracking () {
      bootstrap().then(gtag => {
        console.log(gtag);
      })
    }
  }
}
</script>

<style>

.ec_cookielaw {
  background-color: #da3856 !important;
  color: white !important;
  font-size: 14px !important;
  padding: 22px 20px !important;
}

.ec_cookielaw .ec_props {
  display: inline-flex !important;
  max-width: 1000px !important;
  margin: 0 auto !important;
  width: 100% !important;
}

.ec_cookielaw .ec_props span {
  line-height: 2 !important;
}

.close_cookies {
  margin-right: 20px !important;
}

.more_cookies {
  text-decoration: none !important;
  font-weight: bold !important;
}


</style>