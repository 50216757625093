<template>
  <div class="ec_footer_container">

    <div class="ec_footer_frame">

      <div class="ec_footer_column">
        <span class="ec_footer_element col_title">Assistenza Clienti</span>
        <span class="ec_footer_element link"><router-link to="/contact">Contatti e Segnalazioni</router-link></span>
      </div>

      <div class="ec_footer_column">
        <span class="ec_footer_element col_title">Note Legali</span>
        <span class="ec_footer_element link"><router-link to="/terms">Termini e Condizioni</router-link></span>
        <span class="ec_footer_element link"><router-link to="/privacy">Privacy Policy</router-link></span>
      </div>

      <div class="ec_footer_column">
        <span class="ec_footer_element col_title">Scopri di più</span>
        <span class="ec_footer_element link">
          <router-link to="/social">Social media</router-link>
        </span>
        <span class="ec_footer_element link">
          <router-link to="/jobs">Opportunità di lavoro</router-link>
        </span>
      </div>

      <div class="ec_footer_column last">
        <span class="ec_footer_element col_title">Aderisci</span>
        <span class="ec_footer_element link">
          <router-link to="/register-as-vendor">Registrati come venditore</router-link>
        </span>
      </div>

      <div class="ec_footer_bottom">
        {{ new Date().getFullYear() }}® magentadelivery.it è un'iniziativa defuego 🔥 <br>
        <span class="ec_footbott_light">TREPUNTOZERO</span>
      </div>
    </div>

    <div
        v-if="isRestaurant && session.client_type == 'mobile'"
        class="ec_footblock_occupy">
    </div>
  </div>

</template>

<script>

import { mapState } from 'vuex';

export default {
  data: () => ({

  }),

  computed: {

    ...mapState(['session']),

    isRestaurant() {

      if (this.$route.name == 'restaurant') {
        return true;
      }
      return false;
    },
  },
}

</script>


<style>

.ec_footer_container {
  width: 100%;
  float: left;
  background-color: #da3856;
  border-top: 50px solid #ca3250;
  color: white;
  padding: 40px 0 50px;
  font-size: 15px;
}

.ec_footer_container a {
  text-decoration: none;
}

.ec_footer_frame {
  max-width: 1000px;
  margin: 0 auto;
  float: none;
}

.ec_footer_column {
  width: 25%;
  float: left;
}

.ec_footer_element {
  float: left;
  width: 100%;
  padding: 5px 0;
}

.ec_footer_element.col_title {
  font-weight: bold;
}

.ec_footer_bottom {
  float: left;
  width: 100%;
  text-align: left;
  margin-top: 60px;
  color: white;
}

.ec_footbott_light {
  opacity: .7;
  font-weight: normal;
}

@media (max-width: 600px) {
  .ec_footer_container {
    padding: 10px 10px 80px;
    font-size: 13px;
  }

  .ec_footer_column {
    width: 50%;
    margin-top: 10px;
  }

  .ec_footblock_occupy {
    display: flex;
    width: 100%;
    padding: 60px 0 0;
  }

  .ec_footer_bottom {
    margin-top: 20px;
  }
}

</style>