<template>

  <div>

    <v-select class="ec_ord_ass_select" color="#027d88" label="Orario" :items="selectable_times" v-model="selected_time" @change="selectTime($event)"></v-select>

  </div>

</template>

<script>


export default {
  name: 'timepicker',
  data: () => ({


    default_times: [

      "00:00",
      "01:00",
      "02:00",
      "03:00",
      "04:00",
      "05:00",
      "06:00",
      "07:00",
      "08:00",
      "09:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
      "20:00",
      "21:00",
      "22:00",
      "23:00",

    ]

  }),

  props: {
    selected_time: null,
    label: {time: function(){return null;}},
    times: {default: function(){return [];}}


  },



  computed: {


    selectable_times: function(){


      if(this.times.length == 0){


        return this.default_times;

      }


      return this.times;


    }

  },

  methods: {


    selectTime(time){

      this.$emit('select',time);


    }

  },

  mounted: function(){





  },


}
</script>