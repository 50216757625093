<template>
  <v-app>

    <Tracking></Tracking>

    <div id="app">

      <div>

        <v-main v-if="!session.ready">
          <v-layout align-center justify-center>

            <v-progress-linear indeterminate color="#e05466" style="height: 4px" >
            </v-progress-linear>

          </v-layout>
        </v-main>

      </div>


      <div v-if="session.ready">


        <div v-if="$route.meta.hide_default_navigation == true">

          <v-main>
            <router-view/>
          </v-main>

        </div>


        <div v-else>

          <page-header></page-header>

          <v-main>
            <router-view/>
          </v-main>

          <page-footer></page-footer>

        </div>




      </div>


    </div>
  </v-app>
</template>


<script>


  import PageHeader from './components/page_header.vue'
  import PageFooter from './components/page_footer'
  import Tracking from './components_frontend/Tracking'
  import { mapState } from 'vuex';
  import ApiClient from '@/services/APIService.js'



  export default {
    name: 'App',
    components: {

      PageHeader,
      PageFooter,
      Tracking

    },

    data() {
      return {

        server: process.env.VUE_APP_FORCED_SERVER,
        vendor: 'freshbar',
        isLoading: true,
        config: []

      }
    },

    mounted: function(){


      let self = this;

      this.setClientType();


      window.onresize = function(){self.setClientType();};

      this.$store.dispatch('session/setServer', this.server);
      this.$store.dispatch('session/init');

      if (navigator.geolocation) {

       /* this.$getLocation({}).then(coordinates => {
          self.$store.dispatch('session/setGeolocation', coordinates);
        });*/

      }




      ApiClient.interceptors.response.use(function (response) {

        return response;

      }, function (error) {

        if(error.response.status == "401"){

          location.reload();

        }

      });


    },


    methods: {



      setClientType() {




        if(window.innerWidth > 600){

          this.$store.dispatch('session/setClientType', 'desktop');

        } else {

          this.$store.dispatch('session/setClientType', 'mobile');


        }

      }


    },

    computed: {

      ...mapState(['session'])

    }


  }


</script>


<style src="./App.scss" lang="scss"></style>