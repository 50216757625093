import ApiClient from '@/services/APIService.js'

export const namespaced = true;

export const state = {


    client_type: null,
    server: null,
    login_error: null,
    logging_in: false,

    supports_geolocation: false,
    location: JSON.parse(sessionStorage.getItem('geolocation')),

    selected_city: null,
    loading_vendor: true,


    order_preferences: {


        city: null,
        shipping_method: null,
        delivery_date: null,
        delivery_time: null

    },

    application_locale: null,

    vendor: null,

    user: null,

    ready: false,

    token: localStorage.getItem('mdtoken'),

    portal: {

        name: process.env.VUE_APP_NAME,
        prefix: process.env.VUE_APP_PREFIX,

    }



}


export const mutations = {

    SET_APPLICATION_LOCALE(state,value){

        state.application_locale = value;

    },


    SET_VENDOR(state, vendor){


        state.vendor = vendor;

    },

    SET_SERVER(state,value){

        ApiClient.defaults.baseURL = value;
        state.server = value;

    },

    READY(state){

        state.ready = true;

    },

    SET_SESSION(state, data){
        

        if(data.token != undefined){
            ApiClient.defaults.headers.common['Token'] = data.token;
            localStorage.setItem('mdtoken', data.token);
            state.token = data.token;

        } else {

            ApiClient.defaults.headers.common['Token'] = state.token;
            localStorage.setItem('mdtoken', state.token);

        }

        state.user = data;

    },

    RESET_PREFERENCES(state){


        state.order_preferences = {


            city: null,
            shipping_method: null,
            delivery_date: null,
            delivery_time: null

        };

    },

    RESET_SESSION(state){

        state.token = null;



    },

    SET_GEOLOCATION(state, data){

        if(data != false){

            state.location = data;
            state.supports_geolocation = true;

        } else {

            state.location = false;
            state.supports_geolocation = false;

        }

        sessionStorage.setItem('geolocation',JSON.stringify(data));

    }

}

export const getters = {

    getCurrentLocale: state => {

        return state.application_locale;

    },


    getCurrentLocation: state => {

        return state.location;

    },

    getOrderPreferences: state => {

        return state.order_preferences;

    },

}

export const actions = {




    init({dispatch, state}){


        if(state.token != null){

            ApiClient.defaults.headers.common['Token'] = state.token;

        }


        dispatch('reloadSession');



    },


    reloadSession({commit, dispatch}){
        
        ApiClient.post('/pos/v1/session').then((response) => {

            commit('SET_SESSION',response.data.payload);
            commit('READY');

        }).catch(function () {

            dispatch('resetSession');

        });

    },
    
    
    resetSession({commit, dispatch}){


        commit('RESET_SESSION');

        localStorage.removeItem('mdtoken');
        ApiClient.defaults.headers.common['Token'] = null;


        dispatch('reloadSession');


    },

    login({state,dispatch},credentials){


        state.login_error = null;
        state.logging_in = true;

        ApiClient.post('/auth/login', credentials).then(function(response){

            if(response.data.payload == false){

                state.login_error = response.data.error;

            } else {


                state.token = response.data.payload;
                ApiClient.defaults.headers.common['Token'] = state.token;
                dispatch('reloadSession');

            }



            state.logging_in = false;

        }) .catch(function (error) {
            // handle error
            state.logging_in = false;
            console.log(error);

        });

    },

    logout({dispatch}){


        dispatch('resetSession');

    },

    resetSearchPreferences({commit}){


        commit('RESET_PREFERENCES');


    },

    setClientType({state},type){


        state.client_type = type;

    },

    setGeolocation({commit},data){

        commit('SET_GEOLOCATION',data);

    },

    setServer({commit},server){

        commit('SET_SERVER',server);

    },

    setLocale({commit}, locale){

        commit('SET_APPLICATION_LOCALE',locale);

    },

    resetVendor({commit}){

        commit('SET_VENDOR',null);


    },

    loadVendor({commit},vendor){


        state.vendor = null;
        state.loading_vendor = true;

        ApiClient.get('/pos/v1/'+vendor+'/info').then((response) => {

            if(response.data.payload == false){

                window.location.href = '/';

            }

            commit('SET_VENDOR',response.data.payload);
            state.loading_vendor = false;

        }) .catch(function (error) {
            // handle error

            console.log(error);
        });




    }


}