<template>

  <v-dialog fullscreen hide-overlay v-model="dialog">

    <v-card>
      <v-toolbar class="ec_dialog_toolbar" dark color="#da3856">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>Torna a magentadelivery.it</v-toolbar-title>
      </v-toolbar>

      <!-- See RegisterAsVendor.vue for info on Iframes and Security.... -->

      <iframe class="ec_iframe_full" src="https://mailchi.mp/magdel/vendors-signup"></iframe>

    </v-card>
  </v-dialog>

</template>

<script>

export default {


  data: () => ({

    dialog: false


  }),
  methods: {

    openDialog(){

      this.dialog = true;

    }

  }

}

</script>

<style>

.ec_dialog_toolbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.ec_iframe_full {
  width: 100vw;
  height: 99vh;
  padding-top: 65px;
}

</style>