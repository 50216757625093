import ApiClient from '@/services/APIService.js'


export const namespaced = true;

export const state = {


    shops: false,
    selected_shop: localStorage.getItem('magdel:vendor:shop'),


    loading_orders: false,
    orders: false,




}


export const mutations = {


    SET_SHOPS: function(state,data){


        state.shops = data;

    },


    SET_ORDERS: function(state, data){


        state.orders = data;

    }

}



export const getters = {



}

export const actions = {



    loadShops({commit}){

        let params = {is_active: true, page_size: 100};

        ApiClient.post('/api/erp/v2/shops/search',params).then((response) => {


            commit('SET_SHOPS',response.data.payload.results);


        });


    },


    loadOrders({commit,state}){


        state.loading_orders = true;

        let params = {

            shop: [state.selected_shop],
            order_status: [
                'processing',
                'confirmed',
                'pending-confirmation',
                'preparing-for-shipment',
                'in-transit'
            ],
            expand: ['address','shipping','items']
            //date_created: {start: "2021-01-01"}
        };

        ApiClient.post('/api/erp/v2/order/search',params).then((response) => {


            commit('SET_ORDERS',response.data.payload.results);
            state.loading_orders = false;


        });

    }


}