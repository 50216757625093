<template>

  <div class="general page_header">

    <v-app-bar class="ec_topbar_container" app flat>
      <div class="ec_topbar">

        <div class="ec_topbar_element back_icon"
             v-if="isRestaurant">

          <v-btn icon color="white"
                 @click="$router.go(-1)">

            <v-icon>mdi-arrow-left</v-icon>

          </v-btn>
        </div>

        <router-link :to="{ name: 'home'}">
          <v-toolbar-title class="ec_topbar_element title">
            <img class="logo" :src="require('@/assets/magentadelivery.png')" v-if="session.portal.prefix == 'MAGENTA'"/>
          </v-toolbar-title>
        </router-link>

        <v-badge
            class="ec_cart_cont_badge"
            v-if="isRestaurant && session.client_type == 'mobile' && cart.order.length != 0"
            bordered
            color="primary"
            :content="cart.order.items.length"
            overlap
        >
          <v-btn icon color="white" @click="toggleMobileCart()">
            <v-icon>mdi-cart</v-icon>
          </v-btn>
        </v-badge>

      </div>
    </v-app-bar>

  </div>

</template>

<script>

import {mapState} from 'vuex';

export default {

  components: {



  },

  data: () => ({


    cart_open: false


  }),


  methods: {


    toggleMobileCart: function(){


      this.$root.$emit('toggle-mobile-cart');

    }

  },

  computed: {

    ...mapState(['session', 'cart']),

    isRestaurant() {

      if (this.$route.name == 'checkout') {
        return true;
      }
      if (this.$route.name == 'restaurant') {
        return true;
      }

      return false;

    },

    isCheckout() {


      if (this.$route.name == 'checkout') {
        return true;
      }

      return false;
    }

  }


}

</script>

<style>

.ec_topbar_container {
  background-color: #da3856 !important;
}

.ec_topbar {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.ec_topbar_element {
  justify-items: flex-start;
}

.ec_topbar_element.back_icon button,
.ec_topbar_element.cart button {
  margin-right: 15px;
}

.ec_topbar_element .logo {
  max-width: 250px;
  display: flex;
  justify-self: left;
  padding: 10px 0;
}

.ec_cart_cont_badge .v-badge__badge {
  top: 12px !important;
  font-weight: bold;
}


@media (max-width: 1049px) {
  .ec_topbar {
    width: 100%;
    justify-content: space-around;
    padding: 25px 0;
  }

  .ec_topbar_element i {
    font-size: 18px !important;
  }

  .ec_topbar_element.back_icon button,
  .ec_topbar_element.cart button {
    margin: 0;
    border-radius: 100%;
  }

  .ec_topbar_element .logo {
    max-width: 175px;
  }

  @media (min-width: 601px) and (max-width: 1049px) {

  }

  @media (max-width: 600px) {

  }
}

</style>